//Use this import for old browsers and new js features
import ReactDOM from "react-dom/client";
import reportWebVitals from "reportWebVitals";

import LoginForm from "shared/components/Auth/LoginForm";
import { RestorePass } from "shared/components/Auth/RestorePass";
import { UnconnectedErrorBoundary as ErrorBoundary } from "shared/components/ErrorBoundary";

import { initSentry } from "shared/helpers/utils";

import "i18n";

import "@brandAssets/scss/login.scss";

initSentry();

ReactDOM.createRoot(document.getElementById("login")).render(
  <ErrorBoundary>
    <LoginForm />
  </ErrorBoundary>
);
ReactDOM.createRoot(document.getElementById("restore")).render(
  <ErrorBoundary>
    <RestorePass />
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
